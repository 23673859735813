import React from "react";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/partners-section.module.css";

const PartnersStratigic = (props) => {

  const {
    content,
    partners
  } = props;

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-sm">
            {content.heading && (<h2 className="h1">{content.heading}</h2>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-lg">
            {content._rawContent && (<PortableText blocks={content._rawContent} />)}
          </div>
        </div>
      </div>
      {partners && partners.length ? (
        <div className={cn("grid", styles.partnersList)}>
          {partners.map(({_key, name, location}) => (
            <div key={_key} className={cn("col-12 col-sm-6 col-lg-4 col-xl-3", styles.partner)}>
              <div className={styles.inner}>
                <div className="content-xxs">
                  {name && (<h3 className={styles.name}>{name}</h3>)}
                  {location && (<p className={styles.desc}>{location}</p>)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default PartnersStratigic;
