import React, { useState, useEffect } from "react";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import { Link } from 'react-scroll';

import * as styles from "../styles/components/page-header-new.module.css";

const PageHeader = (props) => {
  const {
    title,
    description
  } = props;

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-md">
            {title && (<h1 className={styles.title}>{title}</h1>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-lg content-m-xxxs">
            {description && (<p className="h1">{description}</p>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
