import React, { useLayoutEffect } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import PageHeader from "../components/page-header-new";
import PartnersRegular from "../components/partners-regular-new";
import PartnersStratigic from "../components/partners-stratigic";

import * as styles from "../styles/components/partners-main-new.module.css";

const PartnersMain = (props) => {

  const {
    pageHeader,
    map,
    markers,
    partners,
    partnersStratigic
  } = props;

  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline();

  useLayoutEffect(() => {
    setTimeout(() => {
      tl.to('#partners-bg', {
        opacity: 1,
        ease: "none",
      });

      tl.to('#partners-bg', {
        scrollTrigger: {
          trigger: 'body',
          start: "top bottom", // the default values
          end: "bottom top",
          scrub: true
        },
        yPercent: 50,
        ease: 'none',
      });
    }, 500);
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.bg} id="partners-bg">
          <div className="m-hide">
            <img src="/images/DNA_plan.png" alt="" />
          </div>
          <div className="d-hide">
            <img src="/images/DNA_plan_mobile.png" alt="" />
          </div>
        </div>

        {pageHeader && (<PageHeader {...pageHeader} />)}

        {partners && (<PartnersRegular {...partners} />)}

        {partnersStratigic && (<PartnersStratigic {...partnersStratigic} />)}
      </Container>
    </div>
  );
}

export default PartnersMain;
