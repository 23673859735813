import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PartnersMain from "../components/partners-main-new";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query PartnersPageQuery {
    partners: sanityPartnersPage {
      pageHeader {
        title
        description
      }
      partners {
        content {
          _rawContent
          heading
        }
        partners {
          _key
          partner {
            name
            description
            link {
              blank
              text
              url
            }
          }
        }
      }
      partnersStratigic {
        content {
          heading
          _rawContent
        }
        partners {
          _key
          location
          name
        }
      }
      callToAction {
        title
        button {
          blank
          noArrow
          primaryButton
          text
          url
        }
      }
    }
  }
`;

const PartnersPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const partners = (data || {}).partners;

  return (
    <Layout>
      <Seo
        title={partners.pageHeader.title}
        description={partners.pageHeader.description}
      />

      <PartnersMain pageHeader={partners.pageHeader} partners={partners.partners} partnersStratigic={partners.partnersStratigic} />

      {partners.callToAction && <CallToAction {...partners.callToAction} />}
    </Layout>
  );
};

export default PartnersPage;
